/* You can add global styles to this file, and also import other style files */
.console-content {
  height: 100%;
  overflow-y: auto;
}

@media (max-height: 720px) {
  .console-content {
    max-height: 880px;
  }
}

@media (max-height: 1080px) {
  .console-content {
    max-height: 880px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
